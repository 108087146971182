import { globalStore } from '../store';

const translationMixin = {
  mounted: function () {
    this.checkIfSheduleView();
  },
  methods: {
    getYear(dateString) {
      let date = new Date(dateString);
      return date.getFullYear(); 
    },
    getDayOfWeek(date) {
      const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const d = new Date(date);
      return this.trans('dayOfWeek', weekday[d.getDay()]);
    },
    getEventCardClass(event) {
      let stage = event.Stage.toLowerCase();
      stage = stage.replace(new RegExp(' ', 'g'), '_').toLowerCase();
      return `event-card event-card--${stage}`;
    },
    getEventCardId(event) {
      let name = event.Band || event.Headline || event.Id;
      name = name.replace(new RegExp(' ', 'g'), '_').toLowerCase();
      return `metalist-event-card-${name}`;
    },
    getAddToFavoritesId(event) {
      let name = event.Band || event.Headline || event.Id;
      name = name.replace(new RegExp(' ', 'g'), '_').toLowerCase();
      return `metalist-add-to-favorites-${name}`;
    },
    getAddToFavoritesIconId(event) {
      let name = event.Band || event.Headline || event.Id;
      name = name.replace(new RegExp(' ', 'g'), '_').toLowerCase();
      return `metalist-add-to-favorites-icon-${name}`;
    },
    toggleFilterMenu() {
      globalStore.filterMenu = !globalStore.filterMenu;
      if (globalStore.filterMenu) {
        document.getElementById('nav').classList.add('nav-gradient');
      } else {
        document.getElementById('nav').classList.remove('nav-gradient');
      }
    },
    toggleSchedule() {
      globalStore.scheduleView = !globalStore.scheduleView;
      localStorage.setItem('scheduleView', JSON.stringify(globalStore.scheduleView));
    },
    checkIfSheduleView() {
      const enabled = JSON.parse(localStorage.getItem('scheduleView'));

      if (enabled) {
        globalStore.scheduleView = true;
      } else {
        globalStore.scheduleView = false;
      }
    }
  }
};
export default translationMixin;
