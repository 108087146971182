<template>
  <div class="event">
    <div class="event-header">
      <back-button absolute />
      <div class="overlay"></div>
      <div class="background" :style="headerBackground"></div>
    </div>
    <div class="event-body">
      <div class="event-body__title">
        {{ event.Band || event.Headline }}
      </div>
      <div class="event-body__data">
        <template v-for="key in dataKeys">
          <div
            class="event-body__data--line"
            :key="key"
            v-if="getEventProp(key)"
          >
            <div class="key">{{ trans(transRoot, key) }}:</div>
            <div class="value">
              {{ getEventProp(key) }}
            </div>
          </div>
        </template>
      </div>
      <div class="event-body__description" v-if="event.Description">
        <span class="section-title">{{ trans(transRoot, 'about') }}</span>
        <p>
          {{ event.Description }}
        </p>
      </div>
      <template v-if="videoUrls.length > 0">
        <div class="event-body__video">
          <span v-if="videoUrls.length > 1" class="section-title">{{ trans(transRoot, 'videos') }}</span>
          <span v-else class="section-title">{{ trans(transRoot, 'video') }}</span>
          <div v-for="videoUrl in videoUrls" class="event-body__video--holder">
            <iframe
              width="560"
              height="315"
              :src="videoUrl"
              title="YouTube video player"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </div>
    </template>
    </div>
    <div class="event-body__button-container">
      <button
        type="button"
        name="favorites-button"
        class="event-body__button"
        :id="getAddToFavoritesId(event)"
        @click="toggleFavorite()"
      >
        <icon disable-click :type="favorite ? 'minus' : 'plus'" :id="getAddToFavoritesIconId(event)" />
        {{ trans(transRoot, `${this.favorite ? 'remove' : 'add'}Favorite`) }}
      </button>
      <label v-if="notificationsEnabledAndGranted" for="favorites-button">{{
        trans(transRoot, 'footerText')
      }}</label>
    </div>
  </div>
</template>
<script>
import EventFile from '../data/schedule.json';
import backButton from '../components/generic/back-button.vue';
import Icon from '../components/generic/icon.vue';
import translationMixin from '../mixins/translationMixin.js';
import notificationMixin from '../mixins/notificationMixin.js';
import helperMixin from '../mixins/helperMixin.js';

export default {
  metaInfo() {
    return {
      title: `Metalist - ${this.event.Band || this.event.Headline}`,
      meta: [
        {
          name: 'robots',
          content: 'noindex,nofollow'
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: `https://www.metalist.lt/event/${this.event.Id}`
        }
      ]
    };
  },
  components: { backButton, Icon },
  mixins: [translationMixin, helperMixin, notificationMixin],
  data() {
    return {
      transRoot: 'event',
      dataKeys: ['Genre', 'Country', 'Stage', 'Time'],
      events: EventFile,
      buttonText: 'Add to favorites',
      event: {},
      favorite: false
    };
  },
  created() {
    this.event = this.events.find(
      (event) => event.Id == this.$route.params.id
    );
    this.favorite = this.checkIfEventIsInFavorites();
  },
  mounted() {
    document.querySelectorAll('meta[name="theme-color"]').forEach((el) => {
      el.setAttribute('content', 'black');
    });
  },
  methods: {
    getEventProp(key) {
      if (key === 'Time') {
        const dateWord = this.getDayOfWeek(this.event.Date);
        if (!dateWord) return;
        return `${dateWord} ${this.event.StartTime.padStart(5, '0')}`;
      }
      if (key === 'Stage') {
        if (this.currentLang === 'LT') return this.event.StageLT;
        if (this.currentLang === 'EN') return this.event.Stage;
      }
      if (key === 'Country') {
        if (this.currentLang === 'LT') return this.event.CountryLT;
        if (this.currentLang === 'EN') return this.event.Country;
      }
      return this.event[key];
    },
    toggleFavorite() {
      this.favorite = this.checkIfEventIsInFavorites();
      if (this.favorite) {
        // remove from favorites
        this.removeFromFavorites(this.event);
      } else {
        // add to favorites
        this.addToFavorites(this.event);
      }
    },
    checkIfEventIsInFavorites() {
      const favorites =
        JSON.parse(localStorage.getItem('metalist-favorites')) ?? [];
      if (favorites && (favorites.find((ev) => ev.Id === this.event.Id) || favorites.includes(this.event.Headline) || favorites.includes(this.event.Band))) {
        return true;
      }
      return false;
    },
    addToFavorites(event) {
      let favoriteEvents =
        JSON.parse(localStorage.getItem('metalist-favorites')) ?? [];
      if(favoriteEvents.length == 0 || typeof favoriteEvents[0] === 'string')
        favoriteEvents.push(event.Headline ? event.Headline : event.Band);
      else {
        favoriteEvents.push(event);
      }
      localStorage.setItem('metalist-favorites', JSON.stringify(favoriteEvents));
      this.favorite = true;
      // this.registerNotification(event);
    },
    removeFromFavorites(event) {
      let favoriteEvents =
        JSON.parse(localStorage.getItem('metalist-favorites')) ?? [];
      if(favoriteEvents.length == 0 || typeof favoriteEvents[0] === 'string')
        favoriteEvents = favoriteEvents.filter((ev) => ev !== event.Headline && ev !== event.Band);
      else {
        favoriteEvents = favoriteEvents.filter((ev) => ev.Id !== event.Id);
      }
      localStorage.setItem('metalist-favorites', JSON.stringify(favoriteEvents));
      this.favorite = false;
      // this.deleteNotification(event);
    }
  },
  computed: {
    headerBackground() {
      if (!this.event.Image) return;
      return { background: `url(${this.event.Image})` };
    },
    videoUrls() {
      let temp = this.event.Video.split('https').filter(t => t.length > 0);
      let test = temp.map(t => `https${t}`.trim().split('&')[0]);
      return test.map(t => `https://www.youtube.com/embed/${t.split('=')[1]}`);
    }
  }
};
</script>
<style lang="scss" scoped>
.section-title {
  font-size: 21px;
  font-weight: 700;
  color: #141823;
}
.event {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  .event-header {
    position: relative;
    height: 35%;

    .overlay {
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
      background: linear-gradient(
        180deg,
        #000000 0.44%,
        rgba(0, 0, 0, 0.0001) 27.92%,
        rgba(0, 0, 0, 0.0001) 60.35%,
        #000000 97.44%
      );
    }

    .background {
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
      z-index: -1;
      background: url('/eventData/defaultHeaderImage.jpg');
      background-size: cover !important;
      background-position: center !important;
    }

    img {
      width: 100%;
    }
  }
  .event-body {
    margin-top: -10px;
    z-index: 999999;
    position: relative;
    padding: 16px;
    text-align: left;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    background: #ffffff;
    padding-bottom: 200px;

    &__title {
      color: #141823;
      font-weight: 800;
      font-size: 26px;
      margin-bottom: 11px;
    }
    &__data {
      margin-bottom: 20px;
      &--line {
        display: flex;
        .key {
          width: 30%;
          font-weight: 800;
        }
        .value {
          width: 70%;
        }
      }
    }
    &__button-container {
      position: fixed;
      bottom: 50px;
      padding: 16px;
      z-index: 999999;
      background: white;
      width: 100%;
      box-sizing: border-box;
      label {
        display: block;
        padding-top: 8px;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #9197a3;
        text-align: left;
      }
    }

    &__video {
      &--holder {
        margin-top: 1em;
        overflow: hidden;
        position: relative;
        width: 100%;

        &::after {
          padding-top: 56.25%;
          display: block;
          content: '';
        }

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    &__button {
      position: relative;
      background: #b91e1e;
      color: #ffffff;
      font-size: 17px;
      font-weight: 700;
      padding: 14px 51px;
      width: 100%;
      border-radius: 5px;
      border: none;
      box-shadow: -5px 4px 13px 0px #1418234d;
    }
  }
}
</style>
