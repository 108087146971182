const notificationMixin = {
  methods: {
    areNotificationsEnabled() {
      // if ('showTrigger' in Notification.prototype) {
      // return true;
      // }
      return false;
    },
    isNotificationPermissionGranted() {
      // return Notification.permission === "granted";
      return false;
    },
    // async registerNotification(event) {
    //   if (!this.notificationsEnabledAndGranted) {
    //     console.log("Notifications can't be created.");
    //     return;
    //   }
    //   event = JSON.parse(JSON.stringify(event));
    //   event.Date = '2022-06-19';
    //   event.StartTime = '17:15';

    //   const actualStartDateTime = new Date(`${event.Date}T${event.StartTime}`);
    //   const reminderDateTime = new Date(actualStartDateTime - 30 * 60 * 1000);
    //   console.log(`Current date and time: ${new Date()}`);
    //   console.log(`Actual event start: ${actualStartDateTime}`);
    //   console.log(`Notification time: ${new Date(reminderDateTime)}`);

    //   if (reminderDateTime <= new Date()) {
    //     console.log('Event has already passed.');
    //     return;
    //   }
    //   var notificationsForEvent = await this.getNotificationsById();
    //   if (notificationsForEvent.length > 0) {
    //     console.log(`Notification for ${event.Band || event.Headline} already exists.`);
    //     return;
    //   }

    //   console.log(`Registering notification for: ${event.Band || event.Headline}`);
    //   const registration = await navigator.serviceWorker.getRegistration();
    //   const body = event.Band ? `${event.Band} will play in 30 minutes` : `${event.Headline} will start in 30 minutes`;
    //   registration.showNotification('Only 30minutes left!', {
    //     tag: event.Id,
    //     body: body,
    //     icon: '/img/icons/notification-icon 96x96.png',
    //     showTrigger: new TimestampTrigger(reminderDateTime.getTime())
    //   });
    // },
    async getNotificationsById(id) {
      const registration = await navigator.serviceWorker.getRegistration();
      return await registration.getNotifications({
        tag: id,
        includeTriggered: true
      });
    },
    async deleteNotification(event) {
      const registration = await navigator.serviceWorker.getRegistration();
      const notifications = await registration.getNotifications({
        tag: event.Id,
        includeTriggered: true
      });
      notifications.forEach((notification) => notification.close());
    }
  },
  computed: {
    notificationsEnabled() {
      // if ('showTrigger' in Notification.prototype) {
      // return true;
      // }
      return false;
    },
    notificationPermissionGranted() {
      // return Notification.permission === "granted";
      return false;
    },
    notificationsEnabledAndGranted() {
      return this.notificationsEnabled && this.notificationPermissionGranted;
    }
  }
};
export default notificationMixin;
