<template>
  <div class="disclaimer" :class="disclaimerClass">
    <div class="disclaimer__text">
      {{ trans('generic', 'disclaimer') }}
    </div>
  </div>
</template>
<script>
import translationMixin from '../../mixins/translationMixin.js';

export default {
  mixins: [translationMixin],
  data() {
    return {
      isMobile: true,
      expired: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.checkScreenSize();
    }, 1000);
    this.scheduleAutoclose();
    window.addEventListener('resize', () => {
      this.checkScreenSize();
      if (!this.isMobile) {
        this.scheduleAutoclose();
      }
    });
  },
  methods: {
    checkScreenSize() {
      this.isMobile = window.outerWidth < 768;
    },
    scheduleAutoclose() {
      setTimeout(() => {
        this.expired = true;
      }, 5000);
    }
  },
  computed: {
    disclaimerClass() {
      return [
        this.isMobile || this.expired ? '' : 'disclaimer disclaimer--open'
      ];
    }
  }
};
</script>
<style lang="scss">
.disclaimer {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background: #b91e1e;
  height: 0;
  transition: height 0.5s;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  z-index: 900000;

  &--open {
    height: 60px !important;
  }

  &__text {
    width: 80%;
    text-align: center;
  }
}
</style>
