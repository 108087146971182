<template>
    <div style="position: relative">
        <filter-button />
        <filter-menu @stageChange="filterEventsByStage" />
        <schedule
            v-if="showSchedule"
            :events="filteredEvents"
            key="schedule-view"
        />
        <event-list v-else :events="filteredEvents" key="list-view" />
        <search-bar v-if="!showSchedule" v-on:input="filerEventsBySearch" />
    </div>
</template>
<script>
import EventList from '../components/eventList.vue';
import FilterMenu from '../components/filter.vue';
import FilterButton from '../components/generic/filter-button.vue';
import SearchBar from '../components/generic/search-bar.vue';
import Schedule from '../components/schedule.vue';
import ScheduleFile from '../data/schedule.json';
import translationMixin from '../mixins/translationMixin.js';
import { globalStore } from '../store';

export default {
    metaInfo() {
        return {
            title: `Metalist - ${this.showSchedule ? 'Schedule' : 'Events'}`,
            meta: [
                {
                    name: 'robots',
                    content: 'noindex,nofollow'
                }
            ],
            link: [
                {
                    rel: 'canonical',
                    href: 'https://www.metalist.lt'
                }
            ]
        };
    },
    components: { EventList, FilterMenu, Schedule, FilterButton, SearchBar },
    mixins: [translationMixin],
    data() {
        return {
            events: ScheduleFile,
            filteredEvents: ScheduleFile
        };
    },
    created() {
        this.saveRouteName();
    },
    mounted() {
        this.filterEventsByStage();
    },
    methods: {
        saveRouteName() {
            localStorage.setItem('parentRoute', this.$route.name.toLowerCase());
        },
        filerEventsBySearch(searchValue) {
            if (searchValue.length >= 3) {
                this.filteredEvents = this.events.filter((event) => {
                    const text =
                        `${event.Search}${event.Headline}${event.Band}${event.Genre}`.toLowerCase();
                    return text.includes(searchValue.toLowerCase());
                });
            } else {
                this.filterEventsByStage();
            }
        },
        filterEventsByStage() {
            const stagesToHide =
                JSON.parse(localStorage.getItem('StagesToHide')) ?? [];
            this.filteredEvents = this.events.filter(
                (item) => !stagesToHide.includes(item.Stage)
            );
        }
    },
    computed: {
        showSchedule() {
            return globalStore.scheduleView;
        }
    }
};
</script>
