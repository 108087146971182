<template>
  <section class="m-toggle">
    <input
      :id="id"
      v-model="toggleState"
      :disabled="disabled"
      :name="name"
      class="m-toggle__input"
      type="checkbox"
    />
    <span
      :aria-checked="toggleState"
      :aria-disabled="disabled"
      :aria-labelledby="`${id}-label`"
      :aria-readonly="disabled"
      class="m-toggle__content"
      :class="{
        'm-toggle__content--active': toggleState,
        'm-toggle__content--language': language
      }"
      role="checkbox"
      @click="toggle"
    >
      <template v-if="language">
        <span class="language language--EN">EN</span>
        <span class="language language--LT">LT</span>
      </template>
    </span>
    <label
      :id="`${id}-label`"
      :for="id"
      class="m-toggle__label"
      :class="{
        'm-toggle__label--active': toggleState,
        'm-toggle__label--language': language
      }"
    >
      {{ title }}
    </label>
  </section>
</template>

<script>
export default {
  props: {
    activeColor: { type: String, default: '#9FD6AE' },
    darkTheme: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    fontSize: { type: String, default: '16px' },
    fontWeight: { type: String, default: 'normal' },
    name: { type: String, required: true },
    title: { type: String, required: true },
    toggled: { type: Boolean, default: false },
    language: { type: Boolean, default: false }
  },
  data() {
    return { toggleState: this.toggled };
  },
  methods: {
    toggle() {
      if (this.disabled) return;
      this.toggleState = !this.toggleState;
      this.$emit('toggle', this.toggleState);
    }
  },
  computed: {
    id() {
      return this.name.replace(/ /g, '').toLowerCase();
    }
  }
};
</script>
<style lang="scss" scoped>
.m-toggle {
  margin-top: 10px;
  $self: &;
  $toggle-spacing: 2px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  &__label {
    user-select: none;
    font-weight: 700;
    font-size: 17px;
    color: #9197a3;
    white-space: nowrap;
    &--active {
      color: white;
    }
    &--language {
      color: white;
    }
  }
  &__input {
    display: none;
    &:checked {
      & + #{$self}__content {
        &:after {
          left: calc(50% + #{$toggle-spacing});
        }
      }
    }
  }
  &__content {
    position: relative;
    border: 1px solid #9197a3;
    background: #ffffff;
    border-radius: 2em;
    box-sizing: border-box;
    height: 2em;
    outline: 0;
    overflow: hidden;
    padding: $toggle-spacing;
    transition: background-color 0.4s ease;
    width: 4em;
    will-change: background-color;
    &--active {
      border: 1px solid red;
      &:after {
        background: #b91e1e !important;
        box-shadow: 3px 2px 5px 0px #b91e1e78 !important;
      }
    }
    &--language {
      border: 1px solid red !important;
      &:after {
        background: #b91e1e !important;
        box-shadow: 3px 2px 5px 0px #b91e1e78 !important;
      }
      .language {
        position: absolute;
        top: 6px;
        &--EN {
          left: 6px;
        }
        &--LT {
          right: 10px;
        }
      }
    }
    &:after {
      background: linear-gradient(226.35deg, #656a73 4.86%, #9197a3 100%);
      border-radius: 50%;
      box-shadow: 3px 2px 4px 0px #14182381;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: relative;
      transition: left 0.2s ease;
      width: calc(50% - #{$toggle-spacing});
      will-change: left;
    }
  }
}
</style>
