<template>
  <div class="search-button">
    <svg
      @click="$emit('click')"
      class="search-button__icon"
      width="32"
      height="31"
      viewBox="0 0 32 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 12L28.2635 28.2635"
        stroke="#141823"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="white"
        stroke="#141823"
        stroke-width="2"
      />
    </svg>
  </div>
</template>
<script>
import helperMixin from '../../mixins/helperMixin.js';

export default {
  name: 'searchButton',
  mixins: [helperMixin]
};
</script>
<style lang="scss" scoped>
.search-button {
  position: fixed;
  top: 0;
  right: 50px;
  z-index: 9;
  padding: 16px 16px 0 8px;
  background: white;
}
</style>
