<template>
  <div class="event-list">
    <div v-for="date in groupedEvents" :key="date.Date">
      <div class="event-list__date">{{ getDayOfWeek(date.Date) }}</div>
      <div v-for="(hour, index) in date.GroupedByHour" :key="index">
        <div class="event-list__hour">{{ hour.Hour }}</div>
        <event-card
          v-for="event in hour.Events"
          :event="event"
          :key="event.Id"
        />
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import translationMixin from '../mixins/translationMixin.js';
import helperMixin from '../mixins/helperMixin.js';
import eventCard from './generic/event-card.vue';

export default {
  mixins: [translationMixin, helperMixin],
  components: { eventCard },
  props: {
    events: {
      type: Array,
      required: true
    }
  },
  computed: {
    groupedEvents() {
      const groupedByDate = _.groupBy(this.events, (dm) => dm.Date);

      return Object.keys(groupedByDate)
        .map((dateGroupKey) => {
          const groupedByHour = _.groupBy(
            groupedByDate[dateGroupKey],
            (dm) => dm.StartTime.split(':')[0]
          );

          const program2 = Object.keys(groupedByHour).map((hourGroupKey) => {
            const events = groupedByHour[hourGroupKey];
            return {
              Hour: hourGroupKey.padStart(2, '0') + ':00',
              Events: events
            };
          });
          return {
            Date: dateGroupKey,
            GroupedByHour: program2
          };
        })
        .sort((a, b) => (a.Date > b.Date ? 1 : -1));
    }
  }
};
</script>
<style lang="scss">
@import '../assets/style/mixins.scss';

.event-list {
  margin: 0 16px 100px 16px;
  text-align: left;

  &__date {
    text-align: left;
    padding-top: 18px;
    position: sticky;
    top: 0;
    background: #ffffff;
    padding-bottom: 22px;
    font-size: 21px;
    font-weight: 700;

    &:after {
      position: absolute;
      left: 0;
      bottom: 10px;
      content: ' ';
      width: 100%;
      height: 2px;
      background: #000;
      transform: translateX(-30%);
    }
  }

  &__hour {
    font-size: 14px;
    color: gray;
  }

  .event-card {
    display: block;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 8px;
    margin: 4px 0;
    font-weight: bold;
    text-decoration: none;
    background-color: white;
    border: 2px solid #141823;
    color: #141823;

    @include stages;
  }
}
</style>
