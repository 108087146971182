<template>
  <div>
    <filter-button />
    <filter-menu @stageChange="filterEventsByStage" />
    <schedule v-if="showSchedule && favorites.length > 0" :events="favorites" />
    <event-list v-else-if="favorites.length > 0" :events="favorites" />
    <div v-else class="no-data">
      {{ trans('generic', 'empty') }}
    </div>
  </div>
</template>
<script>
import EventList from '../components/eventList.vue';
import FilterMenu from '../components/filter.vue';
import FilterButton from '../components/generic/filter-button.vue';
import Schedule from '../components/schedule.vue';
import ScheduleFile from '../data/schedule.json';

import translationMixin from '../mixins/translationMixin.js';
import helperMixin from '../mixins/helperMixin.js';
import { globalStore } from '../store';

export default {
  mixins: [translationMixin, helperMixin],
  metaInfo() {
    return {
      title: 'Metalist - Favourites',
      meta: [
        {
          name: 'robots',
          content: 'noindex,nofollow'
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: 'https://www.metalist.lt/favorites'
        }
      ]
    };
  },
  components: { Schedule, EventList, FilterMenu, FilterButton },
  data() {
    return {
      favorites: [],
      events: ScheduleFile
    };
  },
  created() {
    localStorage.setItem('parentRoute', this.$route.name.toLowerCase());
  },
  mounted() {
    this.filterEventsByStage();
    if(this.favorites.length > 0 && this.getYear(this.favorites[0].Date) !== this.getYear(this.events[0].Date)) {
      localStorage.removeItem('metalist-favorites');
    }
  },
  methods: {
    filterEventsByStage() {
      let favoriteEvents =
        JSON.parse(localStorage.getItem('metalist-favorites')) ?? [];
      if(favoriteEvents.length > 0 && typeof favoriteEvents[0] === 'string')
        this.favorites = this.events.filter(event => favoriteEvents.includes(event.Headline) || favoriteEvents.includes(event.Band));
      else 
        this.favorites = favoriteEvents;
      const stagesToShow =
        JSON.parse(localStorage.getItem('StagesToHide')) ?? [];
      this.favorites = this.favorites.filter(
        (item) => !stagesToShow.includes(item.Stage)
      );
    }
  },
  computed: {
    showSchedule() {
      return globalStore.scheduleView;
    }
  }
};
</script>
<style lang="scss">
.no-data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
