<template>
  <div class="icon" :style="elementStyle">
    <svg v-if="type === 'plus'" :style="elementStyle" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 1.5V18.5" stroke="white" stroke-width="2" stroke-linecap="square" />
      <path d="M1.5 10L18.5 10" stroke="white" stroke-width="2" stroke-linecap="square" />
    </svg>
    <svg v-else-if="type === 'minus'" :style="elementStyle" width="20" height="20" viewBox="0 0 20 20" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 10L18.5 10" stroke="white" stroke-width="2" stroke-linecap="square" />
    </svg>
    <svg v-else-if="type === 'edit'" :style="elementStyle" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512"
      fill="white">
      <path
        d="M421.7 220.3L188.5 453.4L154.6 419.5L158.1 416H112C103.2 416 96 408.8 96 400V353.9L92.51 357.4C87.78 362.2 84.31 368 82.42 374.4L59.44 452.6L137.6 429.6C143.1 427.7 149.8 424.2 154.6 419.5L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3zM492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75z" />
    </svg>
    <svg v-else-if="type === 'info'" :style="elementStyle" fill="#000000" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px"
      viewBox="0 0 416.979 416.979">
      <g>
        <path
          d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85
    		c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786
    		c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576
    		c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765
    		c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z" />
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  props: { type: { type: String, required: true }, disableClick: { type: Boolean } },
  computed: {
    elementStyle() {
      return this.disableClick ? 'pointer-events: none;' : '';
    }
  }
};
</script>
<style scoped>
.icon {
  position: absolute;
  left: 0;
  padding-left: 14px;
}
</style>
