<template>
  <div
    class="back-button"
    :style="absolute ? 'position: absolute; padding: 20px' : 'padding: 4px;'"
    @click="handleClick()"
  >
    <svg
      width="18"
      height="32"
      viewBox="0 0 18 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 1L1 16L17 31"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: 'backButton',
  props: { absolute: { type: Boolean, default: false } },
  methods: {
    handleClick() {
      if (this.absolute) {
        this.$router.go(-1);
        return;
      }
      this.$emit('click');
    }
  }
};
</script>
<style lang="scss" scoped>
.back-button {
  display: block;
  top: 0;
  left: 0;
  padding: 20px;
  background: transparent;
  z-index: 10;
}
</style>
