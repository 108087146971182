<template>
  <div id="nav">
    <div class="left">
      <div id="curved-corner-bottomleft"></div>
    </div>
    <div class="right">
      <div id="curved-corner-bottomright"></div>
    </div>
    <router-link v-if="showTickets" to="/ticket"><nav-button ticket /></router-link>
    <router-link to="/"><nav-button home /></router-link>
    <router-link to="/favorites"><nav-button favorites /></router-link>
  </div>
</template>
<script>
import NavButton from './generic/nav-button.vue';
export default {
  components: { NavButton },
  computed: {
    showTickets() {
      if (localStorage.getItem('metalist-enableTickets')) {
        return true;
      }
      return false;
    }
  }
};
</script>
<style lang="scss" scoped>
#nav {
  background: #141823;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000000;

  a {
    padding: 0 20px;
  }

  .right {
    position: absolute;
    top: -13px;
    right: 0;
    width: 13px;
    height: 13px;
  }

  .left {
    position: absolute;
    top: -13px;
    left: 0;
    width: 13px;
    height: 13px;
  }
}

.nav-gradient {
  background: none !important;

  .left,
  .right {
    display: none;
  }
}</style>
