import Vue from 'vue';
import VueRouter from 'vue-router';
import Ticket from '../pages/ticket.vue';
import Favorites from '../pages/favourites.vue';
import Events from '../pages/events.vue';
import Event from '../pages/event.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/ticket',
        name: 'Ticket',
        component: Ticket
    },
    {
        path: '/',
        name: 'Home',
        component: Events
    },
    {
        path: '/event/:id',
        name: 'Event',
        component: Event
    },
    {
        path: '/favorites',
        name: 'Favorites',
        component: Favorites
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach(async (to, from, next) => {
    var filterOpen = JSON.parse(localStorage.getItem('filterOpen'));
    var parentRoute = localStorage.getItem('parentRoute');
    localStorage.removeItem('filterOpen');

    if (from.name === 'Home' && filterOpen) {
        next({
            name: parentRoute.charAt(0).toUpperCase() + parentRoute.slice(1)
        });
        location.reload();
    } else if (from.name === 'Favorites' && filterOpen) {
        next({
            name: parentRoute.charAt(0).toUpperCase() + parentRoute.slice(1)
        });
        location.reload();
    } else {
        next();
    }
});

router.afterEach(() => {
    document.body.classList.remove('u-noscroll');
    document.body.classList.remove('u-height-100vh');
    const nav = document.getElementById('nav');
    if (nav) nav.classList.remove('nav-gradient');
});

export default router;
