import { globalStore } from '../store/index.js';
import translations from '../data/translations.json';

const translationMixin = {
  mounted: function () {
    const globalLang = localStorage.getItem('language');
    if (globalLang) { globalStore.lang = globalLang; }
  },
  methods: {
    trans(root, slug) {
      if (!slug) { console.log(`Slug is undefined for ${root}`); return 'WHAT'; }
      return translations[root][this.camelCase(slug)][globalStore.lang];
    },
    camelCase(string) {
      if (!string) console.log('String is undefined camelCase function');
      return string.charAt(0).toLowerCase() + string.slice(1);
    },
    setLanguage(lang) {
      localStorage.setItem('language', lang);
      globalStore.lang = lang;
    },
    toggleLanguage() {
      if (globalStore.lang === 'EN') {
        globalStore.lang = 'LT';
      } else {
        globalStore.lang = 'EN';
      }
      localStorage.setItem('language', globalStore.lang);
    }
  },
  computed: {
    currentLang() {
      return globalStore.lang;
    }
  }
};
export default translationMixin;
