<template>
    <div class="schedule" id="schedule">
        <div
            class="schedule__item"
            :style="firstLastDayOffsetMargin(date)"
            v-for="date in dates"
            :key="`date-group-${date}`"
        >
            <div class="schedule__item--title">
                {{ getDayOfWeek(date) }}
            </div>
            <div class="schedule__item--content">
                <div
                    class="hour-block"
                    v-for="index in hoursInDay(date).length"
                    :key="index"
                >
                    <div class="hour-block--text">
                        {{ hoursInDay(date)[index] }}
                    </div>
                </div>
                <div
                    class="line"
                    v-for="(stageName, stageIndex) in getBatch(date)"
                    :key="stageName"
                >
                    <event-card
                        v-for="event in processedEvents[date][stageName]"
                        :event="event"
                        :key="event.Id"
                        scheduleView
                        :style="generateCardStyle(event, stageIndex)"
                    />
                </div>
            </div>
        </div>
        <div class="marker" ref="marker" :style="markerStyle"></div>
        <div class="now-button" v-if="showNowButton" @click="moveToMarker()">
            {{ trans('generic', 'nowButton') }}
        </div>
    </div>
</template>
<script>
import _ from 'lodash';
import stageFile from '../data/stages.json';
import translationMixin from '../mixins/translationMixin.js';
import helperMixin from '../mixins/helperMixin.js';
import eventCard from './generic/event-card.vue';
export default {
    name: 'schedule',
    mixins: [translationMixin, helperMixin],
    props: { events: { type: Array, required: true } },
    components: { eventCard },
    data() {
        return {
            timeModifier: 3,
            dates: [],
            stages: stageFile,
            markerPosition: 0,
            markerStyle: 'left: 0px; display: none',
            showNowButton: false,
            mainStages: [
                // 'Mass hypnosis',
                // 'West',
                // 'East',
                // 'Barbablu',
                // 'Mirages',

                'LRT OPUS STAGE',
                'TUBORG ZERO LIMITS STAGE',
                'BATTERY STAGE',
                'ELFA STAGE',
                'ART STAGE',
                'KINKY PINK (LOVE) STAGE',
                'Outdoor Stage',
                'Red Cat Stage'
            ]
        };
    },
    mounted() {
        let dates = _.uniq([...this.events].map((event) => event.Date));
        this.dates = _.sortBy(dates);
        this.updateMarkerPosition();

        window.setInterval(() => {
            this.updateMarkerPosition();
        }, 5000 * 60);

        document
            .getElementById('schedule')
            .addEventListener('scroll', this.savePosition, true);

        this.setScroll();
    },
    beforeDestroy() {
        document
            .getElementById('schedule')
            .removeEventListener('scroll', this.savePosition, true);
    },
    methods: {
        savePosition() {
            const scroll = document.getElementById('schedule').scrollLeft;
            localStorage.setItem('schedule-scroll', scroll);
            let screenWidth = window.innerWidth;

            this.showNowButton =
                scroll < this.markerPosition - screenWidth ||
                scroll > this.markerPosition;
        },
        setScroll() {
            let scrollFromMemory = localStorage.getItem('schedule-scroll');
            if (!scrollFromMemory) return;
            let schedule = document.getElementById('schedule');
            this.$nextTick(function () {
                schedule.scrollLeft = Number(scrollFromMemory);
            });
        },
        getBatch(date) {
            if (Object.keys(this.processedEvents).includes(date))
                return Object.keys(this.processedEvents[date]);
            return [];
        },
        firstLastDayOffsetMargin(date) {
            if (date === this.dates[0]) {
                const hours = parseInt(this.firstEvent.StartTime.split(':')[0]);
                return `margin-left: -${hours * 60 * this.timeModifier}px;`;
            }
            if (
                this.dates.length > 1 &&
                date === this.dates[this.dates.length - 1]
            ) {
                return `width: ${
                    (this.lastEvent.Dif + this.lastEvent.Length) *
                    this.timeModifier
                }px`;
            }
        },
        hoursInDay(date) {
            const array = [];
            let start = 0;
            let end = 24;
            if (
                this.dates.length > 1 &&
                date === this.dates[this.dates.length - 1]
            ) {
                end = this.lastEvent.EndTime.split(':')[0];
                if (end === '00') end = 24;
            }
            while (start <= end) {
                if (start === 24) {
                    array.push('00:00');
                } else {
                    array.push(`${String(start).padStart(2, '0')}:00`);
                }
                start++;
            }

            return array;
        },
        updateMarkerPosition() {
            const festivalStartDate = new Date(
                `${this.dates[0]} ${
                    this.firstEvent.StartTime.split(':')[0]
                }:00:00`
            );
            const sorted = this.sortedEvents;
            const festivalEndDate = new Date(
                `${this.dates[this.dates.length - 1]} ${
                    parseInt(sorted[sorted.length - 1].EndTime.split(':')[0]) +
                    1
                }:00:00`
            );
            var current = new Date();
            const env = process.env.NODE_ENV;
            if (env === 'development') {
                current = new Date(this.dates[0]);
                const date = new Date();
                current.setHours(date.getHours());
                current.setMinutes(date.getMinutes());
            }
            const diffTime = current - festivalStartDate;
            if (diffTime < 0 || current > festivalEndDate) {
                return;
            }
            const diffMinutes = Math.ceil(diffTime / (1000 * 60));

            this.markerPosition = diffMinutes * this.timeModifier;
            this.markerStyle = `left: ${this.markerPosition}px`;
        },
        generateCardStyle(event, stageIndex) {
            const verticalOffset = event.Dif * this.timeModifier;
            const horizontalOffset = stageIndex * this.lineHeight;
            const length = event.Length * this.timeModifier;
            return `position: absolute; transform: translate(${verticalOffset}px, ${horizontalOffset}px); width: ${length}px`;
        },
        moveToMarker() {
            const limit = this.markerPosition;
            document.getElementById('schedule').scrollLeft = limit - 40;
        }
    },
    computed: {
        sortedEvents() {
            const sorted = JSON.parse(JSON.stringify(this.events));
            return _.sortBy(sorted, ['Date', 'Start']);
        },
        firstEvent() {
            const sorted = JSON.parse(JSON.stringify(this.events));
            let result = _.sortBy(sorted, ['Date', 'Dif']);
            return result[0];
        },
        lastEvent() {
            const sorted = JSON.parse(JSON.stringify(this.events));
            let result = _.sortBy(sorted, ['Date', 'Dif']);
            return result[result.length - 1];
        },
        lineHeight() {
            const windowHeight = window.innerHeight - 165;
            return windowHeight / this.mainStages.length;
        },
        processedEvents() {
            const events = JSON.parse(JSON.stringify(this.events));

            const groupedByDate = _.groupBy(events, (dm) => dm.Date);
            const result = {};

            Object.keys(groupedByDate).forEach((date) => {
                const dateGroup = groupedByDate[date];
                dateGroup.forEach((ev) => {
                    if (!this.mainStages.includes(ev.Stage)) {
                        ev.Stage = 'Secondary';
                    }
                });
                const groupedByStage = _.groupBy(dateGroup, (dm) => dm.Stage);

                if (groupedByStage.Secondary) {
                    groupedByStage.Secondary.forEach((test) => {
                        const dupes = groupedByStage.Secondary.filter(
                            (wut) => wut.StartTime === test.StartTime
                        );
                        if (dupes.length === 2) {
                            if (!groupedByStage.Backup) {
                                groupedByStage.Backup = [];
                            }
                            groupedByStage.Backup.push(dupes[1]);

                            groupedByStage.Backup = _.uniq(
                                groupedByStage.Backup
                            );
                        }
                    });
                    if (groupedByStage.Backup) {
                        groupedByStage.Backup.forEach((eve) => {
                            groupedByStage.Secondary =
                                groupedByStage.Secondary.filter(
                                    (sss) => sss.Id !== eve.Id
                                );
                        });
                    }
                }

                result[date] = groupedByStage;
            });
            return result;
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../assets/style/mixins.scss';

.now-button {
    position: fixed;
    bottom: 70px;
    left: 50%;
    background: black;
    color: white;
    z-index: 10000;
    padding: 3px 8px;
    text-align: center;
    transform: translate(-50%, 0);
    border-radius: 3px;

    border-radius: 5px;
    box-shadow: -5px 4px 13px 0px #1418234d;
    padding: 10px 15px;
    box-sizing: border-box;
}

.schedule {
    animation: 0.5s;
    transition: 0.5s;
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100vw;
    overflow-x: scroll;
    overflow-y: hidden;
    height: 100vh;

    &__item {
        text-align: left;
        flex-flow: column;
        flex-shrink: 0;
        width: calc(1440px * 3);
        height: 100%;

        &--title {
            position: sticky;
            display: inline;
            left: 0;
            top: 0;
            width: 100%;
            height: 40px;
            padding-top: 16px;
            padding-bottom: 15px;
            padding-left: 16px;
            font-size: 21px;
            font-weight: 700;
        }

        &--content {
            width: 100%;
            height: calc(100vh - 135px);
            border: none;
            margin-top: 30px;
            display: flex;
            flex-direction: row;
            padding-bottom: 20px;
            position: relative;

            .hour-block {
                border-top: 2px solid white;
                margin-top: -2px;
                width: 180px;
                min-width: 180px;
                max-width: 180px;
                height: 100%;
                box-shadow: 1px 0px 0px 0px #9197a3;
                position: relative;

                &--text {
                    position: absolute;
                    bottom: -22px;
                    right: -25px;
                    width: 40px;
                    font-size: 12px;
                    color: #9197a3;
                }
            }

            .event-card {
                position: absolute;
                z-index: 1000;
                text-decoration: none;
                top: 0;
                left: 0;
                border-radius: 5px;
                box-shadow: -5px 4px 13px 0px #1418234d;
                padding: 10px 15px;
                box-sizing: border-box;
                background-color: white;
                border: 2px solid #141823;
                color: #141823;

                &--title {
                    white-space: nowrap;
                    overflow: hidden;
                    position: relative;
                }
                @include stages;
            }
        }
    }
}

.marker {
    width: 1px;
    background: #b91e1e;
    position: absolute;
    top: 58px;
    height: calc(100vh - 135px);

    &:after {
        position: absolute;
        bottom: -22px;
        left: -15px;
        content: 'Now';
        color: #b91e1e;
        font-size: 12px;
    }
}
</style>
