<template>
  <div id="app">
    <disclaimer />
    <router-view />
    <navigation />
  </div>
</template>
<script>
import Disclaimer from './components/generic/disclaimer.vue';
import Navigation from './components/navigation.vue';
export default {
  components: {
    Navigation,
    Disclaimer
  }
};
</script>

<style lang="scss">
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.u-noscroll {
  overflow: hidden !important;
}

.u-height-100vh {
  height: 100vh !important;
}

#curved-corner-bottomleft,
#curved-corner-bottomright {
  width: 13px;
  height: 13px;
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 200%;
    height: 200%;
    position: absolute;
    border-radius: 50%;
    bottom: 0;
  }
}

#curved-corner-bottomleft:before {
  left: 0;
  box-shadow: -13px 13px 0 0 #141823;
}
#curved-corner-bottomright:before {
  right: 0;
  box-shadow: 13px 13px 0 0 #141823;
}
</style>
