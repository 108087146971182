<template>
  <a
    :href="`/event/${event.Id}`"
    :class="getEventCardClass(event)"
    :data-event="event.Band ? event.Band : event.Headline"
    :id="getEventCardId(event)"
  >
    <span class="event-card--title">
      {{ event.Headline ? event.Headline : event.Band }}
    </span>
    <span v-if="scheduleView">
      {{ event.StartTime.padStart(5, '0') }}
    </span>
  </a>
</template>
<script>
import translationMixin from '../../mixins/translationMixin.js';
import helperMixin from '../../mixins/helperMixin.js';

export default {
  name: 'eventCard',
  mixins: [translationMixin, helperMixin],
  props: {
    event: { type: Object, required: true },
    scheduleView: { type: Boolean }
  }
};
</script>
<style scoped>
span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  pointer-events: none;
}
</style>
