<template>
  <div>
    <search-button @click="toggleSearchBar()" />
    <div id="searchBar" class="search-bar" :class="classes">
      <input
        type="search"
        name="search"
        ref="searchInput"
        :value="text"
        @input="(e) => (text = e.target.value)"
        @keyup="$emit('input', text)"
        :placeholder="trans('generic', 'searchPlaceholder')"
        autocomplete="off"
      />
      <a class="close" @click="toggleSearchBar()"></a>
    </div>
  </div>
</template>
<script>
import searchButton from './search-button.vue';
import translationMixin from '../../mixins/translationMixin.js';

export default {
  components: { searchButton },
  mixins: [translationMixin],
  data() {
    return {
      text: '',
      searchBarOpen: false,
      pendingUpdate: false
    };
  },
  mounted() {
    window.visualViewport.addEventListener("scroll", this.viewportHandler);
    window.visualViewport.addEventListener("resize", this.viewportHandler);
  },
  beforeDestroy() {
    window.visualViewport.removeEventListener("scroll", this.viewportHandler);
    window.visualViewport.removeEventListener("resize", this.viewportHandler);
  },
  methods: {
    viewportHandler() {
      if (this.pendingUpdate) return;
      this.pendingUpdate = true;

      requestAnimationFrame(() => {
        this.pendingUpdate = false;

        // Stick to bottom
        if (window.visualViewport.offsetTop >= 0) {
          let style = `translateY(-${Math.max(0, window.innerHeight - window.visualViewport.height - window.visualViewport.offsetTop)}px)`;
          document.getElementById('searchBar').style.transform = style;
          document.getElementById('nav').style.transform = style;
        }
      });
    },
    toggleSearchBar() {
      if (this.searchBarOpen) {
        this.text = '';
        this.$emit('input', this.text);
      } else {
        this.focusOnInput();
      }
      this.searchBarOpen = !this.searchBarOpen;
    },
    focusOnInput() {
      this.$refs.searchInput.focus();
    }
  },
  computed: {
    classes() {
      return { 'search-bar--closed': !this.searchBarOpen };
    }
  }
};
</script>
<style lang="scss" scoped>
.search-bar {
  box-sizing: border-box;
  background: #141823;
  position: fixed;
  bottom: 50px;
  left: 0;
  width: 100%;
  height: 52px;
  padding-left: 16px;
  padding-right: 16px;
  z-index: 9999;
  transition: height 0.5s;
  overflow: hidden;

  &--closed {
    height: 0px !important;
  }

  input {
    box-sizing: border-box;
    height: 42px;
    width: 100%;
    border: 2px solid white;
    border-radius: 2px;
    background: #141823;
    color: white;
    font-size: 18px;
    margin-top: 8px;
    padding-left: 6px;

    &:focus-visible {
      outline: none;
    }
  }

  .close {
    position: absolute;
    right: 28px;
    top: 16px;
    width: 25px;
    height: 25px;
  }

  .close:before,
  .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 25px;
    width: 2px;
    background-color: white;
  }

  .close:before {
    transform: rotate(45deg);
  }

  .close:after {
    transform: rotate(-45deg);
  }
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
</style>
