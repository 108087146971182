<template>
  <div ref="filter" id="mySidenav" :class="menuClass">
    <div class="filter-menu__container">
      <div class="filter-menu__header">
        <back-button @click="toggleFilterMenu()" />
        <span class="filter-menu__header--title">{{
          trans(transRoot, 'filterByStage')
        }}</span>
      </div>
      <div class="filter-menu__body">
        <div class="filter-menu__body--layout">
          <toggle
            name="layoutToggle"
            :key="showSchedule"
            :title="trans(transRoot, 'ScheduleView')"
            @toggle="toggleSchedule()"
            :toggled="showSchedule"
          />
          <toggle
            name="languageToggle"
            :key="langVar"
            :title="trans(transRoot, 'Language')"
            @toggle="toggleLanguage()"
            :toggled="langVar == 'EN'"
            language
          />
        </div>
        <div class="filter-menu__body--stages" v-if="isHomepage">
          <toggle
            v-for="stage in stages"
            :name="getStageName(stage)"
            :title="getStageName(stage)"
            :key="stage.Id"
            @toggle="toggleStage(stage)"
            :toggled="stageIsEnabled(stage)"
          />
        </div>
      </div>
      <div class="filter-menu__footer" v-if="isHomepage">
        <span>{{ trans(transRoot, 'footerText') }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import toggle from './generic/toggle.vue';
import StageFile from '../data/stages.json';
import backButton from './generic/back-button.vue';
import translationMixin from '../mixins/translationMixin.js';
import helperMixin from '../mixins/helperMixin.js';
import { globalStore } from '../store/index.js';

export default {
  components: { toggle, backButton },
  mixins: [translationMixin, helperMixin],
  name: 'FilterMenu',
  watch: {
    showFilterMenu(value) {
      if (value) {
        document.body.classList.add('u-noscroll');
        document.body.classList.add('u-height-100vh');
        localStorage.setItem('filterOpen', 'true');
      } else {
        document.body.classList.remove('u-noscroll');
        document.body.classList.remove('u-height-100vh');
        localStorage.removeItem('filterOpen');
      }
    }
  },
  data() {
    return {
      transRoot: 'filter',
      stages: StageFile
    };
  },
  methods: {
    toggleStage(stage) {
      let stageList = JSON.parse(localStorage.getItem('StagesToHide')) ?? [];
      if (stageList.includes(stage.Stage)) {
        stageList = stageList.filter((x) => x !== stage.Stage);
      } else {
        stageList.push(stage.Stage);
      }

      localStorage.setItem(
        'StagesToHide',
        JSON.stringify(_.sortedUniq(stageList))
      );
      this.$emit('stageChange');
    },
    stageIsEnabled(stage) {
      const stageList = JSON.parse(localStorage.getItem('StagesToHide')) ?? [];
      return !stageList.includes(stage.Stage);
    },
    getStageName(stage) {
      return globalStore.lang === 'EN' ? stage.Stage : stage.StageLT;
    }
  },
  computed: {
    isHomepage() {
      return this.$route.name === 'Home'
    },
    langVar() {
      return globalStore.lang;
    },
    menuClass() {
      return `filter-menu filter-menu${this.showFilterMenu ? '--open' : ''}`;
    },
    showFilterMenu() {
      return globalStore.filterMenu;
    },
    showSchedule() {
      return globalStore.scheduleView;
    }
  }
};
</script>
<style lang="scss" scoped>
.back-icon {
  text-align: left;
  z-index: 1000;
  cursor: pointer;
}

.filter-menu {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  background: radial-gradient(
    145.01% 145.01% at 141.08% 57.85%,
    #470101 0%,
    #000000 100%,
    #000000 100%
  );
  opacity: 0.98;
  overflow-x: hidden;
  transition: 0.5s;

  &--open {
    width: 100vw;
  }

  &__container {
    padding: 16px;
  }

  &__header {
    text-align: left;
    display: flex;
    align-items: center;
    white-space: nowrap;

    &--title {
      padding-left: 16px;
      font-size: 21px;
      font-weight: 700;
      color: white;
    }
  }
  &__body {
    padding-top: 10px;

    &--layout {
      border-bottom: 1px solid white;
      margin-bottom: 15px;
      padding-bottom: 15px;
    }
  }
  &__footer {
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    padding: 0 16px 0 0;
    padding-right: 16px;
    position: absolute;
    bottom: 60px;
    text-align: left;

    span {
      white-space: break-spaces;
      color: #9197a3;
    }
  }
}
</style>
