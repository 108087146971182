<template>
  <div class="ticket">
    <div class="ticket__header">
      <div>
        {{ trans(transRoot, showScanner ? 'scanYourTicket' : 'yourTicket') }}
      </div>
      <div class="ticket__info-icon">
        <icon @click.native="openPopup()" type="info"/>
      </div>
    </div>

    <div class="ticket__qr-container">
      <!-- Full-screen camera -->
      <qrcode-stream v-if="showScanner" @decode="onDecode" @init="onInit" />
      <!-- Generated QR code image -->
      <qr-code v-if="result && !showScanner && !showTicketNumberInput" class="ticket__generated-qr" id="generatedQR"
        :text="result" error-level="H" :version="2"></qr-code>

      <!-- Cancel button -->
      <button v-if="showScanner || showTicketNumberInput" class="ticket__qr-container--cancel button" @click="
        showScanner = false;
      showTicketNumberInput = false;
                ">
        {{ trans(transRoot, 'cancel') }}
      </button>
      <!-- Scan area markers -->
      <template v-if="showScanner">
        <div class="ticket__scan-area">
          <template v-if="cameraLoading">
            <div class="ticket__scan-area--loading-text">
              {{ trans(transRoot, 'cameraLoading') }}
            </div>
          </template>
        </div>
      </template>
      <!-- Instructional image -->
      <div v-else-if="!result && !showScanner && !showTicketNumberInput" class="ticket__empty">
        {{ trans('generic', 'noTicket') }}
      </div>
      <input type="text" ref="ticketNumberInput" v-show="result || showTicketNumberInput" v-model="result"
        v-on:keyup.enter="sumbitTicketNumber()" class="ticket__ticket-number-input" />
    </div>
    <div v-if="!result && !showScanner && !showTicketNumberInput" class="ticket__footer">
      <button class="ticket__footer--button button" @click="scanTicket()">
        <icon type="plus" />
        {{ trans(transRoot, 'scanYourTicket') }}
      </button>
      <button class="ticket__footer--button button" @click="addTicketNumberManually()">
        <icon type="edit" class="icon-center" />
        {{ trans(transRoot, 'inputTicketManually') }}
      </button>
    </div>
    <div v-else-if="result && !showScanner && !showTicketNumberInput" class="ticket__footer">
      <button class="ticket__footer--button button" @click="addNewTicket()">
        <icon type="plus" />
        {{ trans(transRoot, 'addNewTicket') }}
      </button>
    </div>
    <popup v-if="showPopup" @hidePopup="hidePopup()" :body-text="trans(transRoot, 'popupText')" :button-text="trans(transRoot, 'popupButtonText')" />
  </div>
</template>
<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import Icon from '../components/generic/icon.vue';
import Popup from '../components/generic/popup.vue';
import translationMixin from '../mixins/translationMixin.js';
import { json } from 'body-parser';

export default {
  metaInfo() {
    return {
      title: 'Metalist - Ticket',
      meta: [
        {
          name: 'robots',
          content: 'noindex,nofollow'
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: 'https://www.metalist.lt/ticket'
        }
      ]
    };
  },
  components: { QrcodeStream, Icon, Popup },
  mixins: [translationMixin],
  data() {
    return {
      transRoot: 'ticket',
      result: '',
      showScanner: false,
      showTicketNumberInput: false,
      cameraLoading: false,
      showPopup: true
    };
  },
  watch: {
    result(value) {
      if (value !== '') {
        this.showScanner = false;
      }
    }
  },
  created() {
    localStorage.setItem('parentRoute', this.$route.name.toLowerCase());
    localStorage.setItem('metalist-enableTickets', true)
    this.result = localStorage.getItem('ticketCode') ?? '';
    this.showPopup = !JSON.parse(localStorage.getItem('metalist-hidePopup')) ?? true;
  },
  methods: {
    openPopup() {
      this.showPopup = true;
    },
    hidePopup() {
      this.showPopup = false;
      localStorage.setItem('metalist-hidePopup', JSON.stringify(true));
    },
    sumbitTicketNumber() {
      this.showTicketNumberInput = false;
      localStorage.setItem('ticketCode', this.result);
      this.$refs.ticketNumberInput.blur();
    },
    addTicketNumberManually() {
      this.showTicketNumberInput = true;
      this.result = '';
      this.focusOnInput();
    },
    onDecode(result) {
      this.result = result;
      localStorage.setItem('ticketCode', result);
    },
    focusOnInput() {
      var intervalId = setInterval(() => {
        if (!document.getElementById('generatedQR')) {
          console.log('Disapeared');
          clearInterval(intervalId);
          this.$refs.ticketNumberInput.focus();
        }
      }, 100);
    },
    async onInit(promise) {
      this.cameraLoading = true;
      try {
        await promise;
        this.cameraLoading = false;
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = 'ERROR: you need to grant camera access permission';
        } else if (error.name === 'NotFoundError') {
          this.error = 'ERROR: no camera on this device';
        } else if (error.name === 'NotSupportedError') {
          this.error = 'ERROR: secure context required (HTTPS, localhost)';
        } else if (error.name === 'NotReadableError') {
          this.error = 'ERROR: is the camera already in use?';
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'ERROR: installed cameras are not suitable';
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'ERROR: Stream API is not supported in this browser';
        } else if (error.name === 'InsecureContextError') {
          this.error =
            'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    },
    scanTicket() {
      this.result = '';
      this.showScanner = true;
    },
    addNewTicket() {
      this.result = '';
      this.showScanner = false;
      this.showTicketNumberInput = false;
    }
  }
};
</script>
<style lang="scss">
$codeOffset: 50px;
$navigationHeight: 50px;

.ticket {
  &__header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    font-size: 21px;
    font-weight: 700;
    color: black;
    background: white;
    width: 100%;
    text-align: left;
    padding: 20px;
    display: flex;
    white-space: nowrap;
  }

  &__info-icon {
    position: relative;
    padding-top: 2px;
  }


  &__qr-container {
    width: 100%;
    height: 100vh;
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: calc(0px - #{$navigationHeight});

    &--cancel {
      position: absolute;
      bottom: 8px;
      width: 92vw !important;
    }
  }

  &__ticket-number-input {
    border: navajowhite;
    z-index: 999999999999;
    text-align: center;
    font-size: 21px;
    font-weight: 700;
    caret-color: black;
    line-height: $codeOffset;

    &:focus-visible {
      outline: none;
    }
  }

  &__scan-area {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 70vw;
    z-index: 99999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, black 4px, transparent 4px) 0 0,
      linear-gradient(to right, black 4px, transparent 4px) 0 100%,
      linear-gradient(to left, black 4px, transparent 4px) 100% 0,
      linear-gradient(to left, black 4px, transparent 4px) 100% 100%,
      linear-gradient(to bottom, black 4px, transparent 4px) 0 0,
      linear-gradient(to bottom, black 4px, transparent 4px) 100% 0,
      linear-gradient(to top, black 4px, transparent 4px) 0 100%,
      linear-gradient(to top, black 4px, transparent 4px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 50px 50px;

    &--loading-text {
      font-size: 17px;
      font-weight: 700;
    }
  }

  &__empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999999;
  }

  &__footer {
    position: absolute;
    bottom: $navigationHeight;
    left: 0;
    width: 100%;
    padding: 0 16px;
    padding-bottom: 8px;
    box-sizing: border-box;

    &--button {
      position: relative;
    }

    .icon-center {
      transform: translateY(-50%);
      top: 50%;
    }
  }
}

.button {
  background: #b91e1e;
  color: #ffffff;
  font-size: 17px;
  font-weight: 700;
  padding: 14px 51px;
  width: 100%;
  border-radius: 5px;
  border: none;
  margin: 8px 0;
  box-shadow: -5px 4px 13px 0px #1418234d;
}

::v-deep .icon {
      display: inline-block !important;
      position: relative !important;
    }
</style>
