<template>
  <div class="filter-button" @click="toggleFilterMenu">
    <svg
      width="32"
      height="31"
      viewBox="0 0 32 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 12V28"
        stroke="#141823"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4 4V27"
        stroke="#141823"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle
        cx="12"
        cy="27"
        r="3"
        fill="white"
        stroke="#141823"
        stroke-width="2"
      />
      <circle
        cx="20"
        cy="8"
        r="3"
        fill="white"
        stroke="#141823"
        stroke-width="2"
      />
      <circle
        cx="4"
        cy="4"
        r="3"
        fill="white"
        stroke="#141823"
        stroke-width="2"
      />
      <path
        d="M20 11V27"
        stroke="#141823"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28 4V27"
        stroke="#141823"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle
        cx="28"
        cy="20"
        r="3"
        fill="white"
        stroke="#141823"
        stroke-width="2"
      />
    </svg>
  </div>
</template>
<script>
import helperMixin from '../../mixins/helperMixin.js';

export default {
  name: 'filterButton',
  mixins: [helperMixin]
};
</script>
<style lang="scss" scoped>
.filter-button {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  padding: 16px 16px 0 8px;
  background: white;
  box-shadow: -10px 0px 5px 0px white;
}
</style>
