<template>
    <div class="popup">
        <div class="popup__text">
            {{ bodyText }}
        </div>
        <div class="popup__footer">
            <button class="popup__footer-button button" @click="$emit('hidePopup')">
                {{ buttonText }}
            </button>
        </div>
    </div>
  </template>
  <script>
  import translationMixin from '../../mixins/translationMixin.js';
  import helperMixin from '../../mixins/helperMixin.js';
  
  export default {
    name: 'popup',
    mixins: [translationMixin, helperMixin],
    props: {
      bodyText: { type: String, required: true },
      buttonText: { type: String, default: 'OK' }
    }
  };
  </script>
  <style scoped lang="scss">
  .popup {
    position: absolute;
    top: 40%;
    left: 0;
    transform: translate(0, -50%);
    margin: 16px;
    width: calc(100vw - 32px);
    z-index: 10000000000;
    display: block;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 8px;
    font-weight: bold;
    text-decoration: none;
    background-color: white;
    border: 2px solid #141823;
    color: #141823;

    &__text {
        height: 60%;
        text-align: center;
        padding: 24px 14px;
    }
    &__footer-button {
        width: auto;
        padding: 14px 24px;
    }
  }
  </style>
  