<template>
  <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      v-if="ticket"
      fill-rule="evenodd"
      clip-rule="evenodd"
      style="transform: translateY(5px)"
      d="M28.333 8.333H30V2.5A2.5 2.5 0 0 0 27.5 0h-25A2.5 2.5 0 0 0 0 2.5v5.833h1.667a1.667 1.667 0 0 1 0 3.334H0V17.5A2.5 2.5 0 0 0 2.5 20h25a2.5 2.5 0 0 0 2.5-2.5v-5.833h-1.667a1.667 1.667 0 0 1 0-3.334ZM7.917 5h14.166c.69 0 1.25.56 1.25 1.25v7.5c0 .69-.56 1.25-1.25 1.25H7.917c-.69 0-1.25-.56-1.25-1.25v-7.5c0-.69.56-1.25 1.25-1.25Zm.416 8.333h13.334V6.667H8.333v6.666Zm20 4.167v-4.167A3.337 3.337 0 0 1 25 10a3.337 3.337 0 0 1 3.333-3.333V2.5a.834.834 0 0 0-.833-.833h-25a.834.834 0 0 0-.833.833v4.167A3.337 3.337 0 0 1 5 10a3.337 3.337 0 0 1-3.333 3.333V17.5c0 .46.373.833.833.833h25c.46 0 .833-.373.833-.833Z"
      :fill="getColor('ticket')"
    />
    <path
      v-else-if="home"
      fill-rule="evenodd"
      clip-rule="evenodd"
      style="transform: translateX(2px)"
      d="M23.32 3.75H20.52V.703a.703.703 0 0 0-.7-.703h-.466c-.385 0-.7.316-.7.703V3.75H7.463V.703a.703.703 0 0 0-.7-.703h-.466c-.385 0-.7.316-.7.703V3.75H2.799A2.806 2.806 0 0 0 0 6.563v20.625A2.806 2.806 0 0 0 2.798 30H23.32a2.806 2.806 0 0 0 2.799-2.813V6.563a2.806 2.806 0 0 0-2.799-2.812ZM2.797 5.625H23.32c.513 0 .933.422.933.938v2.812H1.866V6.562c0-.515.42-.937.932-.937Zm0 22.5H23.32a.938.938 0 0 0 .933-.938V11.25H1.866v15.938c0 .515.42.937.932.937Zm3.498-9.375a.703.703 0 0 1-.7-.703v-2.344c0-.387.315-.703.7-.703h2.332c.385 0 .7.316.7.703v2.344a.703.703 0 0 1-.7.703H6.296Zm5.597 0h2.332c.385 0 .7-.316.7-.703v-2.344a.703.703 0 0 0-.7-.703h-2.332c-.385 0-.7.316-.7.703v2.344c0 .387.315.703.7.703Zm7.928 0H17.49a.703.703 0 0 1-.7-.703v-2.344c0-.387.316-.703.7-.703h2.332c.385 0 .7.316.7.703v2.344a.703.703 0 0 1-.7.703Zm-7.928 5.625h2.332c.385 0 .7-.316.7-.703v-2.344a.703.703 0 0 0-.7-.703h-2.332c-.385 0-.7.316-.7.703v2.344c0 .387.315.703.7.703Zm-5.597 0a.703.703 0 0 1-.7-.703v-2.344c0-.387.315-.703.7-.703h2.332c.385 0 .7.316.7.703v2.344a.703.703 0 0 1-.7.703H6.296Zm11.194 0h2.331c.385 0 .7-.316.7-.703v-2.344a.703.703 0 0 0-.7-.703H17.49c-.384 0-.7.316-.7.703v2.344c0 .387.316.703.7.703Z"
      :fill="getColor('home')"
    />
    <path
      v-else
      d="M1 13.4739V13.1825C1 9.67034 3.4668 6.67468 6.83008 6.09786C9.0127 5.7165 11.3223 6.46315 12.9141 8.10367L13.5 8.70562L14.042 8.10367C15.6777 6.46315 17.9434 5.7165 20.1699 6.09786C23.5342 6.67468 26 9.67034 26 13.1825V13.4739C26 15.5591 25.1602 17.5539 23.6758 18.9759L14.8525 27.4523C14.4863 27.804 14.0029 28 13.5 28C12.9971 28 12.5137 27.804 12.1475 27.4523L3.32373 18.9759C1.84131 17.5539 1.00001 15.5591 1.00001 13.4739H1Z"
      :fill="getColor('favorites')"
    />
  </svg>
</template>
<script>
export default {
  props: {
    ticket: { type: Boolean, default: false },
    home: { type: Boolean, default: false }
  },
  methods: {
    getColor(route) {
      if (!this.$route) return '#FFFFFF';

      var parentRoute = localStorage.getItem('parentRoute') ?? '';
      if (parentRoute === route || this.$route.name.toLowerCase() === route) {
        return '#B91E1E';
      }
      return '#FFFFFF';
    }
  }
};
</script>
