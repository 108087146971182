import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import VueQRCodeComponent from 'vue-qrcode-component';
import VueMeta from 'vue-meta';

Vue.use(VueMeta);
Vue.component('qr-code', VueQRCodeComponent);

// if ('showTrigger' in Notification.prototype) {
//   Notification.requestPermission(function(status) {
//       console.log('Notification permission status:', status);
//   });
// }

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
